import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  enrollUserAsync,
  getUserEnrollmentAsync,
  getAIMTSyllabusAsync,
  getAIMTByUserBatchAsync,
} from "./aimocktest.async";

const initialState = {
  enrollTestUserLoader: false,
  enrollTestUser: [],
  getUserEnrollLoader: false,
  getUserEnroll: [],
  getSyllabusLoader: false,
  getSyllabus: [],
  usersTestLoader: false,
  usersTest: [],
};

export const aimocktestSlice = createSlice({
  name: "syllabus",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(enrollUserAsync.pending), (state, action) => {
      state.enrollTestUserLoader = true;
    });
    builder.addMatcher(isAnyOf(enrollUserAsync.fulfilled), (state, action) => {
      state.enrollTestUserLoader = false;
      state.enrollTestUser = action.payload.data;
    });
    builder.addMatcher(isAnyOf(enrollUserAsync.rejected), (state, action) => {
      state.enrollTestUserLoader = false;
    });

    builder.addMatcher(
      isAnyOf(getUserEnrollmentAsync.pending),
      (state, action) => {
        state.getUserEnrollLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getUserEnrollmentAsync.fulfilled),
      (state, action) => {
        state.getUserEnrollLoader = false;
        state.getUserEnroll = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getUserEnrollmentAsync.rejected),
      (state, action) => {
        state.getUserEnrollLoader = false;
      }
    );

    builder.addMatcher(
      isAnyOf(getAIMTSyllabusAsync.pending),
      (state, action) => {
        state.getSyllabusLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAIMTSyllabusAsync.fulfilled),
      (state, action) => {
        state.getSyllabusLoader = false;
        state.getSyllabus = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getAIMTSyllabusAsync.rejected),
      (state, action) => {
        state.getSyllabusLoader = false;
      }
    );

    builder.addMatcher(
      isAnyOf(getAIMTByUserBatchAsync.pending),
      (state, action) => {
        state.usersTestLoader = true;
      }
    );
    builder.addMatcher(
      isAnyOf(getAIMTByUserBatchAsync.fulfilled),
      (state, action) => {
        state.usersTestLoader = false;
        state.usersTest = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getAIMTByUserBatchAsync.rejected),
      (state, action) => {
        state.usersTestLoader = false;
      }
    );
  },
});

export default aimocktestSlice.reducer;
