import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "../AxiosClient";

export const enrollUserAsync = createAsyncThunk(
  "web/enrollUser",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/enrollUser`, payload, toolkit);
  }
);

export const getUserEnrollmentAsync = createAsyncThunk(
  "admin/getUserEnrollment",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/getUserEnrollment`, [], toolkit);
  }
);

export const getAIMTSyllabusAsync = createAsyncThunk(
  "web/getAIMTSyllabus",
  async (payload, toolkit) => {
    return await AxiosClient("GET", `/getAIMTSyllabus`, payload, toolkit);
  }
);

export const getAIMTByUserBatchAsync = createAsyncThunk(
  "web/getAIMTByUserBatch",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getAIMTByUserBatch?type=${payload?.type}`,
      payload,
      toolkit
    );
  }
);
